/* eslint-disable react/prop-types */
import { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import * as S from './estilos';
import ImgAssitir from '../../Images/BotaoVideo.png';

import ImgEvento from '../../Images/ImgEvento.jpg';
import ImgEventoResp from '../../Images/ImgEventoResp.jpg';

function FacaEventos(props: any) {

  const videoRef = useRef<HTMLVideoElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMuted, setIsMuted] = useState(true);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.muted = false; // Ativa o áudio
        setIsMuted(false);
        videoRef.current.play();
      }
    }, 300); // Pequeno delay para garantir que a modal já foi aberta
  };

  const handleCloseModal = () => {
    if (videoRef.current) {
      videoRef.current.pause(); // Pausa o vídeo ao fechar a modal
    }
    setIsModalOpen(false);
  };


  const [imageSize, setImageSize] = useState(window.innerWidth <= 800 ? "50px" : "200px");
  const [top, setTop] = useState(window.innerWidth <= 800 ? "20px" : "20px");

  useEffect(() => {
    const handleResize = () => {
      setImageSize(window.innerWidth <= 762 ? "100px" : "200px");
      setTop(window.innerWidth <= 762 ? "20px" : "20px");
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <>
      <S.BgEventos>
    
        <S.ForaEventos>
        {!isModalOpen &&
          <div style={{position:"absolute", right:5, top:top}}>
            
          <img
              src={ImgAssitir} // Altere para o caminho correto da sua imagem
              alt="Tour Virtual"
              onClick={handleOpenModal}
              style={{
                cursor: "pointer",
                // position: "relative",
                // top: top,
                // right: "20px",
                // zIndex: 9999999999999,
                width: imageSize,
                // marginLeft:"auto"
              }}
            />
          </div>}

          {isModalOpen && (
            <div className="modal">
              <span className="close-button" onClick={handleCloseModal}>&times;</span>
              <div className="modal-content">

                <video ref={videoRef} width="100%" controls muted={isMuted} playsInline>
                  <source src="https://villacountry.com.br/uploadnew/video_apresentacao.mp4" type="video/mp4" />
                  Seu navegador não suporta vídeos.
                </video>
              </div>
            </div>
          )}
          <style>{`
        /* Estilos da modal */
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.9);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }

        .modal-content {
          position: relative;
          background: #000;
          padding: 10px;
          border-radius: 8px;
          max-width: 80%;
          width: 1000px;
          text-align: center;
        }

        .close-button {
          position: absolute;
          top: 10px;
          right: 15px;
          font-size: 44px;
          color: white;
          cursor: pointer;
        }

        .close-button:hover {
          color: #f1551f;
        }
      `}</style>
          <a href='https://www.gruposaopauloeventos.com.br/villacountry/' target='_blank'><img alt='Imagem para eventos Villa Country' className='ImgEventos' src={ImgEvento} /></a>
          <a href='https://www.gruposaopauloeventos.com.br/villacountry/' target='_blank'><img alt='Imagem para eventos Villa Country' className='ImgEventosResp' src={ImgEventoResp} /></a>
        </S.ForaEventos>
       
      </S.BgEventos>
    </>
  );
}

export default withRouter(FacaEventos);
