/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import { Alert } from "../atoms";
import ReCAPTCHA from "react-google-recaptcha";

import IconFaleConosco from "../../Images/IconFaleConosco.png";

import Load from "../Load";

function FaleConosco(props: any) {
  const history = useHistory();
  const [load, setLoad] = useState(false);
  const [nome, setNome] = useState("");
  const [departamento, setDepartamento] = useState("");
  const [assunto, setAssunto] = useState("");
  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionAssunto, setSelectedOptionAssunto] = useState("");
  function onChange(value: React.SetStateAction<string>) {
    setCaptcha("xxx");
  }

  const phoneMask = (value: string) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d{4})/, "$1-$2");
  };

  const clearFields = () => {
    setNome("");
    setCelular("");
    setEmail("");
    setMensagem("");
  };

  const handleOptionChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedOption(event.target.value);
  };

  const handleAssuntoChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedOptionAssunto(event.target.value);
  };

  const onSubmit = () => {
    let validaMenssages = [];
    validaMenssages.push(
      <div style={{ marginBottom: "10px" }}>
        Atenção, segue abaixo campos obrigatórios:
      </div>
    );
    let valida = false;

    if (selectedOption === "" || selectedOption === undefined) {
      validaMenssages.push(<div key={"Nome"}>- Departamento</div>);
      valida = true;
    }
    if (selectedOptionAssunto === "" || selectedOptionAssunto === undefined) {
      validaMenssages.push(<div key={"Nome"}>- Assunto</div>);
      valida = true;
    }
    if (nome === "" || nome === undefined) {
      validaMenssages.push(<div key={"Nome"}>- Nome</div>);
      valida = true;
    }
    if (celular === "" || celular === undefined) {
      validaMenssages.push(<div key={"Telefone"}>- Telefone</div>);
      valida = true;
    }

    if (email === "" || email === undefined) {
      validaMenssages.push(<div key={"E-mail"}>- E-mail</div>);
      valida = true;
    } else {
      if (
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        )
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        null;
      } else {
        validaMenssages.push(<div key={"E-mail"}>- E-mail (inválido)</div>);
        valida = true;
      }
    }
    if (mensagem === "" || mensagem === undefined) {
      validaMenssages.push(<div key={"Mensagem"}>- Mensagem</div>);
      valida = true;
    }
    if (valida) {
      Alert(<div>{validaMenssages}</div>);
    } else {
      setLoad(true);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        Departamento: selectedOption,
        Assunto: selectedOptionAssunto,
        Nome: nome,
        Celular: celular,
        Email: email,
        Mensagem: mensagem,
      });

      validaMenssages = [];

      fetch("https://appvillacountry.com.br:35000/contact_website", {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      })
        .then((response) => response.text())
        .then((response: any) => {
          // console.log(response);
          setLoad(false);
          Alert(
            <>
              <div>Formulário de contato enviado com sucesso!</div>
              <div>Em breve entraremos em contato.</div>
            </>
          );
          clearFields();
          validaMenssages = [];
          // setNome('');
          // setEmail('');
          // setCelular('');
          // setMensagem('');
          setLoad(false);
        })
        .catch((error) => console.log("error", error));
    }
  };

  return (
    <>
      <div
        id="Contato"
        style={{ marginTop: "-100px", position: "absolute" }}
      ></div>
      {load && <Load status={true} />}
      <S.BgFaleConosco>
        <S.ForaFaleConosco>
          {/* 
          <div className="ForaAssessoria">
              <h4>ASSESSORIA DE IMPRENSA</h4>
              <span>Fabiana Villela</span>
              <h5>EMAIL - <a href="mailto:email@provedor.com.br">assessoria@villacountry.com.br</a></h5>
          </div>
          */}
          <img alt="Ícone Mensagem" src={IconFaleConosco} />
          <h2>Fale Conosco</h2>
          <form>
            <div className="InputSelect">
              <label htmlFor="selectOptions">Departamento:</label>
              <select
                id="selectOptions"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <option value="">Selecione</option>
                {/* <option value="adriano@agenciatriton.com.br">Aniversários</option>  */}
                <option value="Aniversários;aniversarios@saopauloeventos.com.br">
                  Aniversários
                </option>
                <option value="Comercial;rodrigo@villacountry.com.br">
                  Comercial
                </option>
                <option value="Eventos;eventos@saopauloeventos.com.br">
                  Eventos
                </option>
                <option value="Informações;contato@saopauloeventos.com.br">
                  Informações
                </option>
                <option value="Assessoria de imprensa;assessoria@saopauloeventos.com.br">
                  Assessoria de imprensa
                </option>
                <option value="Gestão de influenciadores;assessoria@saopauloeventos.com.br">
                  Gestão de influenciadores
                </option>
              </select>
            </div>

            <div className="InputSelect">
              <label htmlFor="selectOptions">Assunto:</label>
              <select
                id="selectOptions"
                value={selectedOptionAssunto}
                onChange={handleAssuntoChange}
              >
                <option value="">Selecione</option>
                <option value="Dúvidas">Dúvidas</option>
                <option value="Sugestões">Sugestões</option>
                <option value="Elogios">Elogios</option>
                <option value="Reclamações">Reclamações</option>
              </select>
            </div>

            <input
              type="text"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              id="nome"
              name="nome"
              placeholder="NOME"
            />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              name="email"
              placeholder="E-MAIL"
            />
            <input
              type="text"
              value={celular}
              onChange={(e) => setCelular(e.target.value)}
              id="telefone"
              name="telefone"
              placeholder="TELEFONE"
            />
            <input
              className="InputMsg"
              value={mensagem}
              onChange={(e) => setMensagem(e.target.value)}
              id="mensagem"
              name="mensagem"
              placeholder="MENSAGEM"
            ></input>
          </form>
          <button onClick={() => onSubmit()}>ENVIAR</button>
        </S.ForaFaleConosco>
      </S.BgFaleConosco>
    </>
  );
}

export default withRouter(FaleConosco);
